import { envConfig } from '../env';
import type { MarketConfiguration } from './const';
import {
    ContentfulEnum,
    CurrencyEnum,
    LocaleEnum,
    MarketEnum,
    RegionEnum,
    ServiceLocaleEnum,
} from './const';

const france: MarketConfiguration = {
    code: MarketEnum.fr,
    label: 'France',
    descriptiveLabel: 'French',
    testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
    searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL,
    contentfulLocale: ContentfulEnum.en,
    contentfulCountry: 'France',
    locale: LocaleEnum.fr,
    serviceLocale: ServiceLocaleEnum.fr,
    language: {
        code: 'en',
        label: 'English',
        flag: 'gb',
    },
    currency: CurrencyEnum.eur,
    link: 'https://www.no-ga.com/en-fr',
    termsSlug: 'terms-online',
    loop54Market: MarketEnum.fr,
    adyenMarket: MarketEnum.fr,
    freeShippingThreshold: 100,
    externalStoreId: 'ecom-noga',
    searchPathName: 'search',
    adyenCheckoutEnabled: true,
    walleyCheckoutEnabled: false,
    hasTrustPilot: false,
    region: RegionEnum.europe,
};

export default france;
