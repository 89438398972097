import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query CategoryPageBySlug(
        $locale: String!
        $slug: String!
        $preview: Boolean
        $dictionary: String!
        $limit: Int!
        $textBlockName: String
        $activeMarkets: [String]
    ) {
        pageCollection(
            where: { slug: $slug, type: "Category" }
            locale: $locale
            preview: $preview
            limit: $limit
        ) {
            items {
                ...categoryPage
            }
        }

        dictionary(id: $dictionary, locale: $locale, preview: $preview) {
            listCollection(limit: 100) {
                items {
                    key
                    name
                    value
                }
            }
        }

        textBlockCollection(
            locale: $locale
            where: { name: $textBlockName }
            limit: 1
        ) {
            items {
                ...textBlock
            }
        }
    }
`);
