import { envConfig } from '../env';
import type { MarketConfiguration } from './const';
import {
    ContentfulEnum,
    CurrencyEnum,
    LocaleEnum,
    MarketEnum,
    RegionEnum,
    ServiceLocaleEnum,
} from './const';

const finland: MarketConfiguration = {
    code: MarketEnum.fi,
    label: 'Finland',
    descriptiveLabel: 'Finnish',
    testfreaksClientId: envConfig.NEXT_PUBLIC_FI_TESTFREAKS_CLIENT_ID,
    searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_FI,
    contentfulLocale: ContentfulEnum.fi,
    contentfulCountry: 'Finland',
    locale: LocaleEnum.fi,
    serviceLocale: ServiceLocaleEnum.fi,
    language: {
        code: 'fi',
        label: 'Finnish',
        flag: 'fi',
    },
    currency: CurrencyEnum.eur,
    link: 'https://www.no-ga.com/fi',
    termsSlug: 'ehdot-verkkokauppa',
    loop54Market: MarketEnum.fi,
    adyenMarket: MarketEnum.fi,
    freeShippingThreshold: 100,
    externalStoreId: 'ecom-fi',
    searchPathName: 'haku',
    adyenCheckoutEnabled: false,
    walleyCheckoutEnabled: true,
    walleyB2CStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2C_FI,
    walleyB2BStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2B_FI,
    walleyWidgetEnabled: true,
    hasTrustPilot: true,
    region: RegionEnum.nordics,
};

export default finland;
