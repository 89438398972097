import { envConfig } from '../env';
import type { MarketConfiguration } from './const';
import {
    ContentfulEnum,
    CurrencyEnum,
    LocaleEnum,
    MarketEnum,
    RegionEnum,
    ServiceLocaleEnum,
} from './const';

const denmark: MarketConfiguration = {
    code: MarketEnum.dk,
    label: 'Denmark',
    descriptiveLabel: 'Danish',
    testfreaksClientId: envConfig.NEXT_PUBLIC_DK_TESTFREAKS_CLIENT_ID,
    searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_DK,
    contentfulLocale: ContentfulEnum.dk,
    contentfulCountry: 'Denmark',
    locale: LocaleEnum.dk,
    serviceLocale: ServiceLocaleEnum.dk,
    language: {
        code: 'da',
        label: 'Danish',
        flag: 'dk',
    },
    currency: CurrencyEnum.dkk,
    link: 'https://www.no-ga.com/dk',
    termsSlug: 'kobsbetingelser-online',
    loop54Market: MarketEnum.dk,
    adyenMarket: MarketEnum.dk,
    freeShippingThreshold: 1000,
    externalStoreId: 'ecom-dk',
    searchPathName: 'sogning',
    adyenCheckoutEnabled: false,
    walleyB2CStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2C_DK,
    walleyB2BStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2B_DK,
    walleyCheckoutEnabled: true,
    walleyWidgetEnabled: false,
    hasTrustPilot: true,
    region: RegionEnum.nordics,
};

export default denmark;
