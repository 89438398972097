import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment designerPageProductList on ProductList {
        __typename
        sys {
            id
        }
        count
        heading
        linkText
        commerceData
        link {
            ... on Page {
                slug
            }
        }
    }
    fragment designerPage on Page {
        __typename
        sys {
            id
        }
        type
        slug
        se_slug: slug(locale: "sv-SE")
        no_slug: slug(locale: "nb-NO")
        dk_slug: slug(locale: "da-DK")
        fi_slug: slug(locale: "fi-FI")
        nl_slug: slug(locale: "nl-NL")
        en_slug: slug(locale: "en-GB")
        title
        desktopImage {
            ...contentfulAsset
        }
        desktopBackground {
            ...contentfulAsset
        }
        mobileImage {
            ...contentfulAsset
        }
        mobileBackground {
            ...contentfulAsset
        }
        subtitle
        metaTitle
        metaDescription
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        shortDescription
        commerceId
        commerceData
        commerceType
        commerceName
        componentsCollection(limit: 2) {
            items {
                ...designerPageProductList
            }
        }
        filtersCollection(limit: 25) {
            items {
                ... on Parametric {
                    title
                    attributeName
                    type
                    openByDefault
                    minLabel
                    maxLabel
                    suffix
                    prefix
                }
            }
        }
        footerBanner(where: { activeMarkets_contains_some: $activeMarkets }) {
            ...footerBanner
        }
        featuredCollection(
            limit: 4
            where: { activeMarkets_contains_some: $activeMarkets }
        ) {
            items {
                ...feature
            }
        }
    }
    fragment designerPageSiteinfo on Siteinfo {
        header {
            navigation {
                __typename
                sys {
                    id
                }
                sublinksCollection(limit: 12) {
                    items {
                        __typename
                        sys {
                            id
                        }
                        type
                        title
                        internalLink {
                            ... on EditorialPage {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                            }
                            ... on LandingPage {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                            }
                            ... on Page {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                                commerceData
                                commerceId
                                commerceName
                            }
                            ... on Campaign {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title: campaignTitle
                            }
                        }
                    }
                }
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query DesignerPage(
        $locale: String!
        $designer: String!
        $category: String!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        siteinfoCollection(locale: $locale, limit: 1) {
            items {
                ...designerPageSiteinfo
            }
        }
        designer: page(locale: $locale, id: $designer, preview: $preview) {
            ...designerPage
        }
        category: page(locale: $locale, id: $category) {
            ...categoryPage
        }
    }
`);
