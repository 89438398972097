import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query GenericPage(
        $locale: String!
        $id: String!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        page(locale: $locale, id: $id, preview: $preview) {
            ...genericPage
        }
    }
`);
