import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment headerEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment headerBaseLink on NavigationLink {
        ...headerEntry
        type
        title
        specialLinksTitle
        externalLink
        internalLink {
            ... on EditorialPage {
                ...headerEntry
                slug
            }
            ... on LandingPage {
                ...headerEntry
                slug
            }
            ... on Page {
                ...headerEntry
                slug
            }
            ... on Campaign {
                ...headerEntry
                slug
            }
        }
        openInNewTab
    }

    fragment headerSplash on Splash {
        ...headerEntry
        name
        mobileImage {
            ...contentfulAsset
        }
        desktopImage {
            ...contentfulAsset
        }
        href {
            ... on EditorialPage {
                ...headerEntry
                slug
                title
            }
            ... on LandingPage {
                ...headerEntry
                slug
                title
            }
            ... on Page {
                ...headerEntry
                slug
                title
            }
            ... on Campaign {
                ...headerEntry
                slug
                title: campaignTitle
            }
            ... on NavigationLink {
                ...headerEntry
            }
        }
        header
        subheading
    }

    fragment headerNavigationLink on NavigationLink {
        ...headerBaseLink
        specialLinksCollection(limit: 20) {
            items {
                ...headerBaseLink
            }
        }
        rightSectionCollection(limit: 6) {
            items {
                ...headerSplash
            }
        }
        sublinksCollection(limit: 12) {
            items {
                ...headerBaseLink
                sublinksCollection(limit: 28) {
                    items {
                        ...headerBaseLink
                    }
                }
            }
        }
    }

    fragment headerNavigation on Navigation {
        ...headerEntry
        sublinksCollection(limit: 13) {
            items {
                ...headerNavigationLink
            }
        }
    }

    fragment headerStore on Store {
        ...headerEntry
        title
        type
        phoneNumber
        email
        address
        city
        postalCode
        googleMapsUrl
        storePage {
            slug
        }
        infoText
        storePageLinkText
        openingHoursCollection(limit: 7) {
            items {
                name
                openingTime
                closingTime
                dayOfTheWeek
                closed
            }
        }
        exceptionalOpeningHoursCollection(limit: 5) {
            items {
                name
                date
                title
                closed
                closingTime
                openingTime
            }
        }
    }

    fragment headerContactNavigation on Navigation {
        ...mainNavigationEntry
        name
        sublinksCollection(limit: 12) {
            items {
                ...headerBaseLink
            }
        }
    }

    fragment header on Header {
        ...headerEntry
        title
        mobileLogo {
            ...contentfulAsset
        }
        desktopLogo {
            ...contentfulAsset
        }
        leftLinksCollection(limit: 3) {
            items {
                ...headerBaseLink
            }
        }
        uspCollection(limit: 3) {
            items {
                ...headerBaseLink
            }
        }
        stores: storeAndCustomerServiceInfoCollection(limit: 5) {
            items {
                ...headerStore
            }
        }
        navigation {
            ...headerNavigation
        }
        contactNavigation {
            ...headerContactNavigation
        }
    }
`);

export default graphql(/* GraphQL */ `
    query Header($locale: String!, $id: String!, $preview: Boolean) {
        header(id: $id, locale: $locale, preview: $preview) {
            ...header
        }
    }
`);
