import { envConfig } from '../env';
import type { MarketConfiguration } from './const';
import {
    ContentfulEnum,
    CurrencyEnum,
    LocaleEnum,
    MarketEnum,
    RegionEnum,
    ServiceLocaleEnum,
} from './const';

const poland: MarketConfiguration = {
    code: MarketEnum.pl,
    label: 'Poland',
    descriptiveLabel: 'Polish',
    testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
    searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL,
    contentfulLocale: ContentfulEnum.en,
    contentfulCountry: 'Poland',
    locale: LocaleEnum.pl,
    serviceLocale: ServiceLocaleEnum.pl,
    language: {
        code: 'en',
        label: 'English',
        flag: 'gb',
    },
    currency: CurrencyEnum.eur,
    link: 'https://www.no-ga.com/en-pl',
    termsSlug: 'terms-online',
    loop54Market: MarketEnum.pl,
    adyenMarket: MarketEnum.pl,
    freeShippingThreshold: 100,
    externalStoreId: 'ecom-noga',
    searchPathName: 'search',
    adyenCheckoutEnabled: true,
    walleyCheckoutEnabled: false,
    hasTrustPilot: false,
    region: RegionEnum.europe,
};

export default poland;
