import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query ProductInfo(
        $locale: String!
        $brand: [String!]!
        $designer: [String!]!
        $preview: Boolean
        $recommendedPriceTextBlockName: String!
    ) {
        brand: pageCollection(
            where: { commerceId_in: $brand, commerceType: "Brands" }
            locale: $locale
            limit: 5
            preview: $preview
        ) {
            items {
                ...productPageBrand
            }
        }
        designer: pageCollection(
            where: { commerceId_in: $designer, commerceType: "Designers" }
            locale: $locale
            limit: 5
            preview: $preview
        ) {
            items {
                ...productPageDesigner
            }
        }
        productPageInfoCollection(limit: 1, locale: $locale) {
            items {
                ...productPageInfo
            }
        }
        recommendedPriceTextBox: textBlockCollection(
            locale: $locale
            where: { name: $recommendedPriceTextBlockName }
            limit: 1
        ) {
            items {
                ...textBlock
            }
        }
    }
`);
