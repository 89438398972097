import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query GenericPageBySlug(
        $locale: String!
        $slug: String!
        $limit: Int!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        pageCollection(
            locale: $locale
            where: { slug: $slug }
            limit: $limit
            preview: $preview
        ) {
            items {
                ...genericPage
            }
        }
    }
`);
