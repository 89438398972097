import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query TextblockByName($name: String!, $locale: String!) {
        textBlockCollection(locale: $locale, where: { name: $name }) {
            items {
                title
                content {
                    json
                }
            }
        }
    }
`);
