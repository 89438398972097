import { z } from 'zod';

export enum RegionEnum {
    nordics = 'nordics',
    europe = 'europe',
}

export type LanguageConfiguration = {
    code: string;
    label: string;
    flag: string;
};

export enum MarketEnum {
    at = 'at' /** Austria */,
    be = 'be' /** Belgium */,
    cz = 'cz' /** Czechia */,
    dk = 'dk' /** Denmark */,
    de = 'de' /** Germany */,
    ee = 'ee' /** Estonia */,
    es = 'es' /** Spain */,
    eu = 'eu' /** Europe */,
    fi = 'fi' /** Finland */,
    fr = 'fr' /** France */,
    gr = 'gr' /** Greece */,
    hr = 'hr' /** Croatia */,
    hu = 'hu' /** Hungary */,
    it = 'it' /** Italy */,
    lt = 'lt' /** Lithuania */,
    lv = 'lv' /** Latvia */,
    nl = 'nl' /** Netherlands */,
    no = 'no' /** Norway */,
    pl = 'pl' /** Poland */,
    pt = 'pt' /** Portugal */,
    ro = 'ro' /** Romania */,
    se = 'se' /** Sweden */,
    si = 'si' /** Slovenia */,
    sk = 'sk' /** Slovakia */,
}

export enum LocaleEnum {
    at = 'en-at' /** Austria */,
    be = 'en-be' /** Belgium */,
    cz = 'en-cz' /** Czechia */,
    dk = 'dk' /** Denmark */,
    de = 'en-de' /** Germany */,
    ee = 'en-ee' /** Estonia */,
    es = 'en-es' /** Spain */,
    eu = 'eu' /** Spain */,
    fi = 'fi' /** Finland */,
    fr = 'en-fr' /** France */,
    gr = 'en-gr' /** Greece */,
    hr = 'en-hr' /** Croatia */,
    hu = 'en-hu' /** Hungary */,
    it = 'en-it' /** Italy */,
    lt = 'en-lt' /** Lithuania */,
    lv = 'en-lv' /** Latvia */,
    nl = 'nl' /** Netherlands */,
    no = '' /** Norway */,
    pl = 'en-pl' /** Poland */,
    pt = 'en-pt' /** Portugal */,
    ro = 'en-ro' /** Romania */,
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    se = '' /** Sweden */,
    si = 'en-si' /** Slovenia */,
    sk = 'en-sk' /** Slovakia */,
}

export enum ServiceLocaleEnum {
    at = 'en-at' /** Austria */,
    be = 'en-be' /** Belgium */,
    cz = 'en-cz' /** Czechia */,
    dk = 'da-dk' /** Denmark */,
    de = 'en-de' /** Germany */,
    ee = 'en-ee' /** Estonia */,
    es = 'en-es' /** Spain */,
    fi = 'fi-fi' /** Finland */,
    fr = 'en-fr' /** France */,
    gr = 'en-gr' /** Greece */,
    hr = 'en-hr' /** Croatia */,
    hu = 'en-hu' /** Hungary */,
    it = 'en-it' /** Italy */,
    lt = 'en-lt' /** Lithuania */,
    lv = 'en-lv' /** Latvia */,
    nl = 'nl-nl' /** Netherlands */,
    no = 'nb-no' /** Norway */,
    pl = 'en-pl' /** Poland */,
    pt = 'en-pt' /** Portugal */,
    ro = 'en-ro' /** Romania */,
    se = 'sv-se' /** Sweden */,
    si = 'en-si' /** Slovenia */,
    sk = 'en-sk' /** Slovakia */,
}

export enum ContentfulEnum {
    en = 'en-GB',
    se = 'sv-SE',
    no = 'nb-NO',
    fi = 'fi-FI',
    dk = 'da-DK',
    nl = 'nl-NL',
}

export enum CurrencyEnum {
    eur = 'EUR',
    sek = 'SEK',
    nok = 'NOK',
    dkk = 'DKK',
}

export type MarketCode =
    | 'at' /** Austria */
    | 'be' /** Belgium */
    | 'cz' /** Czechia */
    | 'dk' /** Denmark */
    | 'de' /** Germany */
    | 'ee' /** Estonia */
    | 'es' /** Spain */
    | 'eu' /** Europe */
    | 'fi' /** Finland */
    | 'fr' /** France */
    | 'gr' /** Greece */
    | 'hr' /** Croatia */
    | 'hu' /** Hungary */
    | 'it' /** Italy */
    | 'lt' /** Lithuania */
    | 'lv' /** Latvia */
    | 'nl' /** Netherlands */
    | 'no' /** Norway */
    | 'pl' /** Poland */
    | 'pt' /** Portugal */
    | 'ro' /** Romania */
    | 'se' /** Sweden */
    | 'si' /** Slovenia */
    | 'sk' /** Slovakia */;

export const contentfulLocales = [
    'en-GB',
    'sv-SE',
    'nb-NO',
    'fi-FI',
    'da-DK',
    'nl-NL',
] as const;

export type ContentfulLocale = (typeof contentfulLocales)[number];

// would love to make this dynamic from the values above...
export const slugZodObject = z.object({
    'nb-NO': z.string().optional(),
    'sv-SE': z.string().optional(),
    'fi-FI': z.string().optional(),
    'da-DK': z.string().optional(),
    'en-GB': z.string().optional(),
    'nl-NL': z.string().optional(),
});

type WalleyCheckoutConfiguration =
    | {
          walleyCheckoutEnabled: true;
          walleyB2BStoreId: number;
          walleyB2CStoreId: number;
          walleyWidgetEnabled: boolean;
      }
    | {
          walleyCheckoutEnabled: false;
          walleyB2BStoreId?: never;
          walleyB2CStoreId?: never;
          walleyWidgetEnabled?: never;
      };

export type MarketConfiguration = WalleyCheckoutConfiguration & {
    code: MarketEnum;
    region: RegionEnum;
    label: string;
    descriptiveLabel: string;
    testfreaksClientId: string;
    searchEngineUrl: string;
    contentfulLocale: ContentfulEnum;
    contentfulCountry: string;
    // should this be "slug" or something instead of locale?
    // is locale useful for sv, no?
    // Seems like it is only used for building urls / links
    locale: LocaleEnum;
    serviceLocale: ServiceLocaleEnum;
    language: LanguageConfiguration;
    currency: string;
    link: string;
    termsSlug: string;
    /**
     * Markets like EU don't have any data in Loop54
     * associated with their market code, so their
     * queries are done toward another market code.
     */
    loop54Market: MarketEnum;
    adyenMarket: MarketEnum;
    externalStoreId: string;
    searchPathName: string;
    adyenCheckoutEnabled: boolean;
    hasTrustPilot: boolean;
    freeShippingThreshold: number;
};
