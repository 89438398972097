import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query ServicePageBySlug(
        $locale: String!
        $slug: String!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        pageCollection(
            locale: $locale
            where: { slug: $slug }
            preview: $preview
            limit: 1
        ) {
            items {
                ...servicePage
            }
        }
    }
`);
