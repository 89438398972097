import { envConfig } from '@/config/env';

import markets from './markets';
import {
    LocaleEnum,
    type MarketCode,
    type MarketConfiguration,
    MarketEnum,
    ServiceLocaleEnum,
} from './markets/const';

// TODO:
/**
 * Move testfreaks client id from envConfig into here
 * Move search engine url from envconfig into here
 */

export const marketConfigurations: MarketConfiguration[] = [...markets];

export const getMarketConfigFromLocale = (
    locale?: string,
): MarketConfiguration => {
    // Sweden and Norway have empty locale
    if (locale) {
        const market = marketConfigurations.find(
            (market) => market.locale === locale,
        );
        if (market) {
            return market;
        }
    }
    return getDefaultMarket();
};

export const getMarketConfigFromCode = (
    marketCode?: string,
): MarketConfiguration => {
    const market = marketConfigurations.find(
        (market) => market.code === marketCode,
    );
    if (market) {
        return market;
    }
    return getDefaultMarket();
};

export const getDefaultMarket = () => {
    const fallbackMarket = marketConfigurations.find(
        (market) => market.code === envConfig.NEXT_PUBLIC_APP_MARKET,
    );
    // null coalesce only for type safety, should never happen
    return fallbackMarket ?? marketConfigurations[0];
};

export const isValidMarket = (market?: string) => {
    if (!market) return false;
    return Object.values<string>(MarketEnum).includes(market);
};

export const isValidLocale = (locale?: string) => {
    const appMarket = envConfig.NEXT_PUBLIC_APP_MARKET;

    if (appMarket === 'se') {
        return locale === LocaleEnum.se;
    }

    if (appMarket === 'no') {
        return locale === LocaleEnum.no;
    }

    const euMarkets = marketConfigurations.filter(
        (m) => m.code !== 'se' && m.code !== 'no',
    );

    return euMarkets.some((market) => market.locale === locale);
};

export const isValidServiceLocale = (serviceLocale?: string) => {
    const appMarket = envConfig.NEXT_PUBLIC_APP_MARKET;
    if (appMarket === 'se') {
        return serviceLocale === ServiceLocaleEnum.se;
    }
    if (appMarket === 'no') {
        return serviceLocale === ServiceLocaleEnum.no;
    }
    const euMarkets = marketConfigurations.filter(
        (m) => m.code !== 'se' && m.code !== 'no',
    );
    return euMarkets.some((market) => market.serviceLocale === serviceLocale);
};

export const getMarketConfigsSortedByLabel = () => {
    return marketConfigurations.sort((a, b) => a.label.localeCompare(b.label));
};

export function getLink(from: MarketConfiguration, to: MarketConfiguration) {
    if (isSameBaseUrl(from, to)) {
        return envConfig.NEXT_PUBLIC_APP_BASE_URL + '/' + to.locale;
    }
    return to.link;
}

function isSameBaseUrl(from: MarketConfiguration, to: MarketConfiguration) {
    if (from.code === to.code) {
        return true;
    }
    const singleSiteCodes = ['se', 'no'];
    return (
        !singleSiteCodes.includes(from.code) &&
        !singleSiteCodes.includes(to.code)
    );
}

/** These are not with the other configs because only simple
 * objects / types can be passed between server and client,
 * and RegExp is not such a simple type.
 */
export const getZipCodeRegexByMarketCode = (marketCode: MarketCode) => {
    switch (marketCode) {
        case 'at':
            return /^\s*\d{4}\s*$/;
        case 'be':
            return /^\s*\d{4}\s*$/;
        case 'cz':
            return /^\s*\d{3}\s?\d{2}\s*$/;
        case 'de':
            return /^\s*\d{5}\s*$/;
        case 'dk':
            return /^\s*\d{4}\s*$/;
        case 'ee':
            return /^\s*\d{5}\s*$/;
        case 'es':
            return /^\s*\d{5}\s*$/;
        case 'fi':
            return /^\s*\d{5}\s*$/;
        case 'fr':
            return /^\s*\d{5}\s*$/;
        case 'gr':
            return /^\s*\d{3}\s?\d{2}\s*$/;
        case 'hr':
            return /^\s*\d{5}\s*$/;
        case 'hu':
            return /^\s*\d{4}\s*$/;
        case 'it':
            return /^\s*\d{5}\s*$/;
        case 'lt':
            return /^\s*\d{5}\s*$/;
        case 'lv':
            return /^\s*(LV-)\d{4}\s*$/;
        case 'nl':
            return /^\s*\d{4}\s?[A-Za-z]{2}\s*$/;
        case 'no':
            return /^\s*\d{4}\s*$/;
        case 'pl':
            return /^\s*\d{2}[\-]\d{3}\s*$/;
        case 'pt':
            return /^\s*\d{4}([\-]\d{3})\s*$/;
        case 'ro':
            return /^\s*\d{6}\s*$/;
        case 'se':
            return /^\s*\d{3}\s?\d{2}\s*$/;
        case 'si':
            return /^\s*\d{4}\s*$/;
        case 'sk':
            return /^\s*\d{3}\s\d{2}\s*$/;
        default:
            return /asdf/;
    }
};

/**
 * Map our locale to the the correspondig localized Adyen locale
 * to make sure we use a supported language in the Adyen drop-in widget
 * @url https://docs.adyen.com/online-payments/build-your-integration/sessions-flow/?platform=Web&integration=Drop-in&version=6.0.3#localization -  Adyen localization doumentation
 * @url https://github.com/Adyen/adyen-web/tree/main/packages/server/translations - List of all supported locales
 */
export function mapLocaleToAdyenLocale(locale?: string) {
    switch (locale) {
        case 'en-GB':
            return 'en-US';
        case 'nb-NO':
            return 'no-NO';
        case 'sv-SE':
            return 'sv-SE';
        case 'fi-FI':
            return 'fi-FI';
        case 'da-DK':
            return 'da-DK';
        case 'nl-NL':
            return 'nl-NL';
        default:
            return 'en-US';
    }
}

export const determineSiteName = () => {
    switch (envConfig.NEXT_PUBLIC_APP_MARKET) {
        case 'se':
            return 'ngse';
        case 'no':
            return 'ngno';
        case 'de':
            return 'ngeu';
        default:
            throw new Error(
                'Unable to determine site name, invalid `env.NEXT_PUBLIC_APP_MARKET`',
            );
    }
};
