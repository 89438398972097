import { envConfig } from '../env';
import type { MarketConfiguration } from './const';
import {
    ContentfulEnum,
    CurrencyEnum,
    LocaleEnum,
    MarketEnum,
    RegionEnum,
    ServiceLocaleEnum,
} from './const';

const sweden: MarketConfiguration = {
    code: MarketEnum.se,
    label: 'Sweden',
    descriptiveLabel: 'Swedish',
    testfreaksClientId: envConfig.NEXT_PUBLIC_SE_TESTFREAKS_CLIENT_ID,
    searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_SE,
    contentfulLocale: ContentfulEnum.se,
    contentfulCountry: 'Sweden',
    locale: LocaleEnum.se,
    serviceLocale: ServiceLocaleEnum.se,
    language: {
        code: 'sv',
        label: 'Swedish',
        flag: 'se',
    },
    currency: CurrencyEnum.sek,
    link: 'https://www.nordiskagalleriet.se',
    termsSlug: 'kopvillkor-e-handel',
    loop54Market: MarketEnum.se,
    adyenMarket: MarketEnum.se,
    freeShippingThreshold: 1000,
    externalStoreId: 'ecom-se',
    searchPathName: 'sok',
    adyenCheckoutEnabled: false,
    walleyCheckoutEnabled: true,
    walleyB2CStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2C_SE,
    walleyB2BStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2B_SE,
    walleyWidgetEnabled: true,
    hasTrustPilot: true,
    region: RegionEnum.nordics,
};

export default sweden;
