import { envConfig } from '../env';
import type { MarketConfiguration } from './const';
import {
    ContentfulEnum,
    CurrencyEnum,
    LocaleEnum,
    MarketEnum,
    RegionEnum,
    ServiceLocaleEnum,
} from './const';

const norway: MarketConfiguration = {
    code: MarketEnum.no,
    label: 'Norway',
    descriptiveLabel: 'Norwegian',
    testfreaksClientId: envConfig.NEXT_PUBLIC_NO_TESTFREAKS_CLIENT_ID,
    searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_NO,
    contentfulLocale: ContentfulEnum.no,
    contentfulCountry: 'Norway',
    locale: LocaleEnum.no,
    serviceLocale: ServiceLocaleEnum.no,
    language: {
        code: 'nb',
        label: 'Norwegian',
        flag: 'no',
    },
    currency: CurrencyEnum.nok,
    link: 'https://www.nordiskagalleriet.no',
    termsSlug: 'kjopsvilkar-online',
    loop54Market: MarketEnum.no,
    adyenMarket: MarketEnum.no,
    freeShippingThreshold: 1000,
    externalStoreId: 'ecom-no',
    searchPathName: 'sog',
    adyenCheckoutEnabled: false,
    walleyCheckoutEnabled: true,
    walleyB2CStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2C_NO,
    walleyB2BStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2B_NO,
    walleyWidgetEnabled: true,
    hasTrustPilot: true,
    region: RegionEnum.nordics,
};

export default norway;
