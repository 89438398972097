/* eslint-disable import/no-anonymous-default-export */
import { FEATURE_FLAGS } from '../featureFlags';
import { default as austria } from './austria';
import { default as belgium } from './belgium';
import { default as croatia } from './croatia';
import { default as czechia } from './czechia';
import { default as denmark } from './denmark';
import { default as estonia } from './estonia';
import europe from './eu';
import { default as finland } from './finland';
import { default as france } from './france';
import { default as germany } from './germany';
import { default as greece } from './greece';
import { default as hungary } from './hungary';
import { default as italy } from './italy';
import { default as latvia } from './latvia';
import { default as lithuania } from './lithuania';
import { default as netherlands } from './netherlands';
import { default as norway } from './norway';
import { default as poland } from './poland';
import { default as portugal } from './portugal';
import { default as romania } from './romania';
import { default as slovakia } from './slovakia';
import { default as slovenia } from './slovenia';
import { default as spain } from './spain';
import { default as sweden } from './sweden';

const markets = Boolean(FEATURE_FLAGS.marketSelector.new)
    ? [
          austria,
          belgium,
          croatia,
          czechia,
          denmark,
          estonia,
          finland,
          france,
          germany,
          greece,
          hungary,
          italy,
          latvia,
          lithuania,
          netherlands,
          norway,
          poland,
          portugal,
          romania,
          slovakia,
          slovenia,
          spain,
          sweden,
      ]
    : [
          europe,
          austria,
          belgium,
          denmark,
          finland,
          france,
          germany,
          netherlands,
          norway,
          spain,
          sweden,
      ];

export default markets;
