import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query FooterBanner(
        $locale: String!
        $activePage: [String!]!
        $activeMarkets: [String!]!
    ) {
        footerBannerCollection(
            locale: $locale
            where: {
                activePage_contains_some: $activePage
                activeMarkets_contains_some: $activeMarkets
            }
        ) {
            items {
                ...footerBanner
            }
        }
    }
`);
