import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment categoryPageProductList on ProductList {
        sys {
            id
        }
        __typename
        count
        heading
        linkText
        commerceData
        link {
            ... on Page {
                slug
            }
        }
    }
    fragment baseCategoryLink on NavigationLink {
        __typename
        sys {
            id
        }
        title
        type
        sublinksCollection(limit: 12) {
            total
            items {
                __typename
                sys {
                    id
                }
                title
                internalLink {
                    ... on Page {
                        __typename
                        sys {
                            id
                        }
                        commerceId
                        slug
                    }
                    ... on EditorialPage {
                        __typename
                        sys {
                            id
                        }
                        slug
                    }
                    ... on LandingPage {
                        __typename
                        sys {
                            id
                        }
                        slug
                    }
                    ... on Campaign {
                        __typename
                        sys {
                            id
                        }
                        slug
                        commerceId
                    }
                }
            }
        }
        internalLink {
            ... on Page {
                __typename
                sys {
                    id
                }
                commerceId
                slug
            }
            ... on EditorialPage {
                __typename
                sys {
                    id
                }
                slug
            }
            ... on LandingPage {
                __typename
                sys {
                    id
                }
                slug
            }
            ... on Campaign {
                __typename
                sys {
                    id
                }
                slug
                commerceId
            }
        }
    }
    fragment categoryPageLink on NavigationLink {
        __typename
        sys {
            id
        }
        type
        title
        internalLink {
            ... on EditorialPage {
                __typename
                sys {
                    id
                }
                slug
                title
            }
            ... on LandingPage {
                __typename
                sys {
                    id
                }
                slug
                title
            }
            ... on Page {
                __typename
                sys {
                    id
                }
                slug
                title
                commerceData
                commerceId
                commerceName
            }
            ... on Campaign {
                __typename
                sys {
                    id
                }
                slug
                title: campaignTitle
            }
        }
        sublinksCollection(limit: 12) {
            items {
                __typename
                sys {
                    id
                }
                type
                title
                internalLink {
                    ... on EditorialPage {
                        __typename
                        sys {
                            id
                        }
                        slug
                        title
                    }
                    ... on LandingPage {
                        __typename
                        sys {
                            id
                        }
                        slug
                        title
                    }
                    ... on Page {
                        __typename
                        sys {
                            id
                        }
                        slug
                        title
                        commerceData
                        commerceId
                        commerceName
                    }
                    ... on Campaign {
                        __typename
                        sys {
                            id
                        }
                        slug
                        title: campaignTitle
                    }
                }
            }
        }
    }
    fragment categoryPageLinks on PageLinkingCollections {
        navigationLinkCollection(limit: 3) {
            items {
                ...baseCategoryLink
                linkedFrom(allowedLocales: "sv-SE") {
                    navigationLinkCollection(limit: 3) {
                        items {
                            ...baseCategoryLink
                            linkedFrom(allowedLocales: "sv-SE") {
                                navigationLinkCollection(limit: 3) {
                                    items {
                                        ...baseCategoryLink
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    fragment categoryPage on Page {
        __typename
        sys {
            id
        }
        slug
        se_slug: slug(locale: "sv-SE")
        no_slug: slug(locale: "nb-NO")
        dk_slug: slug(locale: "da-DK")
        fi_slug: slug(locale: "fi-FI")
        nl_slug: slug(locale: "nl-NL")
        en_slug: slug(locale: "en-GB")
        type
        title
        subtitle
        metaTitle
        metaDescription
        commerceId
        commerceData
        commerceType
        commerceName
        sortProductsBy
        footerBanner(where: { activeMarkets_contains_some: $activeMarkets }) {
            ...footerBanner
        }
        ingress {
            json
        }
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        desktopImage {
            ...contentfulAsset
        }
        desktopBackground {
            ...contentfulAsset
        }
        mobileImage {
            ...contentfulAsset
        }
        mobileBackground {
            ...contentfulAsset
        }
        alternativeTermsPage {
            slug
        }
        shortDescription
        sys {
            id
        }
        type
        title
        componentsCollection(limit: 2) {
            items {
                ...categoryPageProductList
            }
        }
        linkedFrom(allowedLocales: "sv-SE") {
            ...categoryPageLinks
        }
        filtersCollection(limit: 25) {
            items {
                ... on Parametric {
                    title
                    attributeName
                    type
                    openByDefault
                    minLabel
                    maxLabel
                    suffix
                    prefix
                }
            }
        }
        featuredCollection(
            limit: 4
            where: { activeMarkets_contains_some: $activeMarkets }
        ) {
            items {
                ...feature
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query CategoryPage(
        $locale: String!
        $id: String!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        page(locale: $locale, id: $id, preview: $preview) {
            ...categoryPage
        }
    }
`);
