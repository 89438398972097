import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment pageinfoEntry on Entry {
        __typename
        sys {
            id
        }
    }
    fragment pageinfoForm on Form {
        ...pageinfoEntry
        name
        reference
        dictionaryCollection(limit: 100) {
            items {
                ... on KeyValue {
                    key
                    name
                    value
                }
            }
        }
    }
    fragment pageinfoPage on Page {
        ...pageinfoEntry
        slug
        type
        title
        subtitle
        metaTitle
        metaDescription
        listType
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
    }
    fragment pageinfoCampaign on Campaign {
        ...pageinfoEntry
        campaignName
        campaignStart
        campaignEnd
        showCountdown
        campaignTitle
        shortDescription
        nordicsCampaignImage
        campaignProductListingImage
        nordicsLongDescription {
            json
        }
        slug
        commerceData
    }
    fragment pageinfoEditorialPage on EditorialPage {
        ...pageinfoEntry
        slug
        title
        metaTitle
        metaDescription
        metaKeywords
    }
    fragment pageinfoLandingPage on LandingPage {
        ...pageinfoEntry
        slug
        title
        metaTitle
        metaDescription
        metaKeywords
    }
    fragment pageinfoNavigation on Navigation {
        ...pageinfoEntry
        sublinksCollection(limit: 12) {
            items {
                ...pageinfoEntry
                type
                title
                internalLink {
                    ... on Page {
                        slug
                        title
                        commerceData
                        commerceId
                        commerceName
                    }
                    ... on Campaign {
                        slug
                        title: campaignTitle
                    }
                }
                sublinksCollection(limit: 10) {
                    items {
                        ...pageinfoEntry
                        type
                        title
                        internalLink {
                            ... on Page {
                                slug
                                title
                                commerceData
                                commerceId
                                commerceName
                            }
                        }
                        sublinksCollection(limit: 10) {
                            items {
                                ...pageinfoEntry
                                type
                                title
                                internalLink {
                                    ... on Page {
                                        ...pageinfoEntry
                                        slug
                                        title
                                        commerceData
                                        commerceId
                                        commerceName
                                    }
                                    ... on Campaign {
                                        ...pageinfoEntry
                                        slug
                                        title: campaignTitle
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query PageInfo(
        $global: String!
        $locale: String!
        $slugs: [String!]!
        $slug: String!
        $dictionary: String!
        $preview: Boolean
    ) {
        page: pageCollection(
            locale: $locale
            limit: 1
            where: { slug: $slug }
            preview: $preview
        ) {
            items {
                ...pageinfoPage
            }
        }
        pages: pageCollection(
            locale: $locale
            limit: 3
            where: { slug_in: $slugs }
            preview: $preview
        ) {
            items {
                ...pageinfoPage
            }
        }
        global: dictionary(id: $global, locale: $locale, preview: $preview) {
            listCollection(limit: 200) {
                items {
                    ...pageinfoEntry
                    name
                    key
                    value
                }
            }
        }
        campaigns: campaignCollection(
            locale: $locale
            limit: 1
            where: { slug_in: $slugs }
            preview: $preview
        ) {
            items {
                ...pageinfoCampaign
            }
        }
        editorialPage: editorialPageCollection(
            locale: $locale
            limit: 1
            where: { slug: $slug }
            preview: $preview
        ) {
            items {
                ...pageinfoEditorialPage
            }
        }
        campaignPage: campaignCollection(
            locale: $locale
            limit: 1
            where: { slug: $slug }
            preview: $preview
        ) {
            items {
                ...pageinfoCampaign
            }
        }
        dictionary(id: $dictionary, locale: $locale, preview: $preview) {
            listCollection(limit: 200) {
                items {
                    ...pageinfoEntry
                    name
                    key
                    value
                }
            }
        }
    }
`);
