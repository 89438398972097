/* eslint-disable import/no-anonymous-default-export */
import { envConfig } from '../env';
import type { MarketConfiguration } from './const';
import {
    ContentfulEnum,
    CurrencyEnum,
    LocaleEnum,
    MarketEnum,
    RegionEnum,
    ServiceLocaleEnum,
} from './const';

const netherlands: MarketConfiguration = {
    code: MarketEnum.nl,
    label: 'Netherlands',
    descriptiveLabel: 'Dutch',
    testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
    searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_NL,
    contentfulLocale: ContentfulEnum.nl,
    contentfulCountry: 'Netherlands',
    locale: LocaleEnum.nl,
    serviceLocale: ServiceLocaleEnum.nl,
    language: {
        code: 'nl',
        label: 'Dutch',
        flag: 'nl',
    },
    currency: CurrencyEnum.eur,
    link: 'https://www.no-ga.com/nl',
    termsSlug: 'algemene-voorwaarden-online-aankopen',
    loop54Market: MarketEnum.nl,
    adyenMarket: MarketEnum.nl,
    freeShippingThreshold: 100,
    externalStoreId: 'ecom-nl',
    searchPathName: 'zoeken',
    adyenCheckoutEnabled: true,
    walleyCheckoutEnabled: false,
    hasTrustPilot: false,
    region: RegionEnum.europe,
};

export default netherlands;
