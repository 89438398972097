import { graphql } from '@server/gql';
export const MagazinePageFragment = graphql(/* GraphQL */ `
    fragment MagazinePageFragment on Page {
        ...magazinePageEntry
        slug
        title
        ingress {
            json
        }
        metaTitle
        metaDescription
        dictionary {
            listCollection {
                items {
                    key
                    name
                    value
                }
            }
        }
        componentsCollection(limit: 1) {
            items {
                ...magazineNavigation
            }
        }

        linkedFrom(allowedLocales: "sv-SE") {
            editorialPageCollection(
                limit: $limit
                skip: $skip
                order: publishingDate_DESC
            ) {
                total
                items {
                    ...baseArticle
                }
            }
        }
        footerBanner(where: { activeMarkets_contains_some: $activeMarkets }) {
            ...footerBanner
        }
    }
`);
export default graphql(/* GraphQL */ `
    query MagazinePageBySlug(
        $slug: String!
        $locale: String!
        $skip: Int!
        $limit: Int!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        pageCollection(
            locale: $locale
            where: { slug: $slug }
            preview: $preview
            limit: 1
        ) {
            items {
                ...MagazinePageFragment
            }
        }
    }
`);
